import { allEnumKeys } from '@framework/utils/app-util';
import { AnalyticsType } from '.';
import { Analytics } from './events';
import { mapObject } from '@framework/utils/translate-util';
import eventDispatcher from './eventDispatcher';

declare const window: any
const ALL_EVENTS: any = Analytics.Events

/**
 * Manages the dispatching of analytics events to configured analytics providers
 */
export class AnalyticsEventManager {

    /**
     * Dispatches an analytics event to all configured analytics providers
     * @param {string} eventType - The type of the event, which should match the key of an event config
     * in the Analytics.Events object
     * @param {Object} eventData - The data to be passed with the event, which will be transformed based on
     * the transformMap in the event config
     */
    public static dispatch(eventType: string, eventData: any) {
        const analyticsProviders = allEnumKeys(AnalyticsType)
        analyticsProviders.forEach((provider: string) => {
            this.dispatchEvent(provider, eventType, eventData);
        })
    }

    /**
     * Dispatches an analytics event to a specific provider (e.g. Google Analytics, Dynamic Yield)
     * @param {string} providerKey - The key of the analytics provider in the Analytics.Events object
     * @param {string} eventType - The type of the event, which should match the key of an event config
     * in the Analytics.Events object
     * @param {Object} eventData - The data to be passed with the event, which will be transformed based on
     * the transformMap in the event config
     */
    private static dispatchEvent(providerKey: string, eventType: string, eventData: any) {

        const providerConfig = ALL_EVENTS[providerKey];
        if (!providerConfig || !providerConfig.events || !providerConfig.events[eventType]) {
            //console.warn(`No event configuration found for ${eventType} on provider ${providerKey}`)
            return
        }

        const eventTypeName = providerConfig?.eventTypes[eventType]
        const eventConfig = providerConfig?.events[eventType]
        const translatedEventData = mapObject(eventData, eventConfig?.transformMap || {})

        // Dispatch the event to the analytics platform (customize based on provider)
        switch (providerKey) {
            case AnalyticsType.KWIKPASS:
                eventDispatcher(eventTypeName, translatedEventData)
                break;
        }
    }
}
