import { useCallback, useEffect } from 'react'
import { AnalyticsEventManager } from './AnalyticsEventManager'

/**
 * useAnalytics hook
 *
 * If analytics is enabled, it provides a function to record analytics events.
 *
 * @returns {Object} an object with a single function, recordAnalytics
 * @property {function(string, Object): void} recordAnalytics - a function to record analytics events
 */
export default function useEventAnalytics(event?: string, data?: any) {
    const dataLayer = typeof window !== 'undefined'
   
    /**
     * Returns a function to record analytics events
     * @returns {function(string, Object): void} recordAnalytics function
     */
    const recordAnalytics = useCallback((event: string, data: any): void => {   
      if (dataLayer) {
        AnalyticsEventManager.dispatch(event, data)
      }
    }, [dataLayer])
   
    useEffect(() => {
      if (dataLayer && event && data) {
        recordAnalytics(event!, data)
      }
    }, [dataLayer])
   
    return { recordAnalytics }
  }